import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import ExhibitionList from '../components/ExhibitionList'
import ExhibitionGrid from '../components/ExhibitionGrid'
import Pagination from '../components/Pagination'

export default class ExhibitionsPage extends React.Component {
  render() {
    const { data, pageContext } = this.props
    const { edges: posts } = data.allWordpressWpExhibitions

    return (
      <div className="exhibitions-page">
        <Helmet title={`Exhibitions | Q Contemporary`} />
        <ExhibitionList posts={posts} range="current" full="true" title="Current exhibitions" />
        <Pagination pageContext={pageContext} pathPrefix="/" />
      </div>
    )
  }
}

ExhibitionsPage.propTypes = {
  data: PropTypes.shape({
    allWordpressWpExhibitions: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  pageContext: PropTypes.shape({
    currentPage: PropTypes.number,
    numPages: PropTypes.number,
  })
}

export const pageQuery = graphql`
  query ExhibitionsQuery {
    allWordpressWpExhibitions(
      sort: {fields: date, order: ASC}, filter: {slug: {ne: "do-not-delete"}}
    ) {
      edges {
        node {
          ...ExhibitionListFields
          ...ExhibitionListFieldsPast
        }
      }
    }
  }
`
